import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="post-details privacy">
      <section className="tf-section detail">
        <div className="container">
          <div className="row">
            <article
              className="article"
              data-aos="fade-in"
              data-aos-duration="800"
            >
              <div className="post p-b23">
                <h4 className="title">Terms and Conditions</h4>
                <span>Effective Date: [Insert Date]</span>
              </div>
              <div className="post-details">
                <h5>1. Acceptance of Terms</h5>
                <p>
                  By accessing or using the PCO Meta Earth website and services
                  ("Services"), you agree to comply with and be bound by these
                  Terms of Service ("Terms"). If you do not agree to these
                  Terms, you should not use our Services.
                </p>
              </div>
              <div className="post-details">
                <h5>2. Use of Services</h5>

                <ul className="list">
                  <li>
                    <div className="me-2 text-white">
                      <svg
                        width="8"
                        height="7"
                        viewBox="0 0 8 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z"
                          fill="white"
                        />
                      </svg>{" "}
                      Eligibility:
                    </div>
                    <p>
                      You must be at least 18 years old to use our Services.
                    </p>
                  </li>

                  <li>
                    <div className="me-2 text-white">
                      <svg
                        width="8"
                        height="7"
                        viewBox="0 0 8 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="me-2"
                      >
                        <path
                          d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z"
                          fill="white"
                        />
                      </svg>
                      Account Registration:
                    </div>
                    <p>
                      You must provide accurate and complete information when
                      creating an account. You are responsible for maintaining
                      the confidentiality of your account credentials.
                    </p>
                  </li>
                  <li>
                    <div className="me-2 text-white">
                      <svg
                        width="8"
                        height="7"
                        viewBox="0 0 8 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="me-2"
                      >
                        <path
                          d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z"
                          fill="white"
                        />
                      </svg>
                      Prohibited Activities:
                    </div>
                    <p>
                      You agree not to engage in any unlawful activities,
                      including but not limited to fraud, harassment, or
                      distributing malicious software.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="post-details">
                <h5>3. Intellectual Property</h5>
                <p>
                  All content, including text, graphics, logos, and software, is
                  the property of PCO Meta Earth and is protected by
                  intellectual property laws. You may not use, reproduce, or
                  distribute any content without our express permission.
                </p>
              </div>
              <div className="post-details">
                <h5>4. User Content</h5>
                <p>
                  You retain ownership of any content you submit to the
                  platform. By submitting content, you grant us a non-exclusive,
                  royalty-free license to use, display, and distribute your
                  content in connection with our Services.
                </p>
              </div>
              <div className="post-details">
                <h5>5. Limitation of Liability</h5>
                <p>
                  PCO Meta Earth is not liable for any indirect, incidental, or
                  consequential damages arising from your use of our Services.
                  Our liability is limited to the amount you paid for our
                  Services, if any.
                </p>
              </div>
              <div className="post-details">
                <h5>6. Termination</h5>
                <p>
                  We reserve the right to suspend or terminate your access to
                  our Services at any time, without notice, for conduct that we
                  believe violates these Terms or is harmful to other users or
                  our platform.
                </p>
              </div>
              <div className="post-details">
                <h5>7. Governing Law</h5>
                <p>
                  These Terms are governed by the laws of [Your Jurisdiction].
                  Any disputes arising from these Terms will be resolved in the
                  courts of [Your Jurisdiction].
                </p>
              </div>
              <div className="post-details">
                <h5>8. Changes to Terms</h5>
                <p>
                  We may update these Terms from time to time. We will notify
                  you of any significant changes by posting the new Terms on our
                  website. Your continued use of the Services constitutes
                  acceptance of the updated Terms.
                </p>
              </div>
              <div className="post-details">
                <h5>8. Contact Us</h5>
                <p>
                  If you have any questions or concerns about this Privacy
                  Policy, please contact us at
                  <a href="mailto:Info@pco.land"> Info@pco.land</a>.
                </p>
              </div>
            </article>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TermsAndConditions;
