import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="post-details privacy">
      <section className="tf-section detail">
        <div className="container">
          <div className="row">
            <article
              className="article"
              data-aos="fade-in"
              data-aos-duration="800"
            >
              <div className="post p-b23">
                <h4 className="title">Privacy Policy</h4>
              </div>
              <div className="post-details">
                <h5>1. Introduction PCO Meta Earth :</h5>
                <p>
                  our values your privacy and is committed to protecting your
                  personal information. This Privacy Policy outlines how we
                  collect, use, and safeguard your data when you use our website
                  and services.
                </p>
              </div>
              <div className="post-details">
                <h5>2. Information We Collect :</h5>

                <ul className="list">
                  <li>
                    <div className="me-2 text-white">
                      <svg
                        width="8"
                        height="7"
                        viewBox="0 0 8 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z"
                          fill="white"
                        />
                      </svg>{" "}
                      Personal Information:
                    </div>
                    <p>
                      Name, email address, contact details, and any other
                      information you provide during registration or through our
                      services.
                    </p>
                  </li>

                  <li>
                    <div className="me-2 text-white">
                      <svg
                        width="8"
                        height="7"
                        viewBox="0 0 8 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="me-2"
                      >
                        <path
                          d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z"
                          fill="white"
                        />
                      </svg>
                      Usage Data:
                    </div>
                    <p>
                      Information about how you use our website and services,
                      including your IP address, browser type, and pages
                      visited.
                    </p>
                  </li>
                  <li>
                    <div className="me-2 text-white">
                      <svg
                        width="8"
                        height="7"
                        viewBox="0 0 8 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="me-2"
                      >
                        <path
                          d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z"
                          fill="white"
                        />
                      </svg>
                      Cookies:
                    </div>
                    <p>
                      Small data files stored on your device to enhance your
                      experience on our site. You can manage cookie preferences
                      through your browser settings.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="post-details">
                <h5>3. How We Use Your Information :</h5>
                <ul className="list">
                  <li>
                    <div className="me-2 text-white">
                      <svg
                        width="8"
                        height="7"
                        viewBox="0 0 8 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="me-2"
                      >
                        <path
                          d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z"
                          fill="white"
                        />
                      </svg>
                      To Provide Services:
                    </div>
                    <p>
                      To manage your account, process transactions, and offer
                      customer support.
                    </p>
                  </li>

                  <li>
                    <div className="me-2 text-white">
                      <svg
                        width="8"
                        height="7"
                        viewBox="0 0 8 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="me-2"
                      >
                        <path
                          d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z"
                          fill="white"
                        />
                      </svg>
                      To Improve Our Services:
                    </div>
                    <p>
                      To analyze usage data and improve our website and
                      offerings.
                    </p>
                  </li>
                  <li>
                    <div className="me-2 text-white">
                      <svg
                        width="8"
                        height="7"
                        viewBox="0 0 8 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="me-2"
                      >
                        <path
                          d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z"
                          fill="white"
                        />
                      </svg>
                      To Communicate:
                    </div>
                    <p>
                      To send you updates, newsletters, and promotional
                      materials, with your consent.
                    </p>
                  </li>
                  <li>
                    <div className="me-2 text-white">
                      <svg
                        width="8"
                        height="7"
                        viewBox="0 0 8 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="me-2"
                      >
                        <path
                          d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z"
                          fill="white"
                        />
                      </svg>
                      For Security:
                    </div>
                    <p>
                      {" "}
                      To detect and prevent fraud and ensure the security of our
                      platform.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="post-details">
                <h5>4. Sharing Your Information :</h5>
                <p>
                  We do not sell or rent your personal information to third
                  parties. We may share your information with:
                </p>
                <ul className="list">
                  <li>
                    <div className="me-2 text-white">
                      <svg
                        width="8"
                        height="7"
                        viewBox="0 0 8 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="me-2"
                      >
                        <path
                          d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z"
                          fill="white"
                        />
                      </svg>
                      Service Providers:
                    </div>
                    <p>
                      Trusted third parties who assist us in operating our
                      website and services. Legal Requirements: When required by
                      law or to protect our rights and safety.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="post-details">
                <h5>5. Data Security :</h5>
                <p>
                  We implement appropriate technical and organizational measures
                  to protect your personal information from unauthorized access,
                  alteration, or disclosure.
                </p>
              </div>
              <div className="post-details">
                <h5>6. Your Rights :</h5>
                <p>You have the right to:</p>
                <ul className="list">
                  <li>
                    <div className="me-2 text-white">
                      <svg
                        width="8"
                        height="7"
                        viewBox="0 0 8 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="me-2"
                      >
                        <path
                          d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z"
                          fill="white"
                        />
                      </svg>
                      Access:
                    </div>
                    <p> Request a copy of your personal information.</p>
                  </li>

                  <li>
                    <div className="me-2 text-white">
                      <svg
                        width="8"
                        height="7"
                        viewBox="0 0 8 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="me-2"
                      >
                        <path
                          d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z"
                          fill="white"
                        />
                      </svg>
                      Rectify:
                    </div>
                    <p> Correct any inaccurate or incomplete data.</p>
                  </li>
                  <li>
                    <div className="me-2 text-white">
                      <svg
                        width="8"
                        height="7"
                        viewBox="0 0 8 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="me-2"
                      >
                        <path
                          d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z"
                          fill="white"
                        />
                      </svg>
                      Erase:
                    </div>
                    <p> Request the deletion of your personal information.</p>
                  </li>
                  <li>
                    <div className="me-2 text-white">
                      <svg
                        width="8"
                        height="7"
                        viewBox="0 0 8 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="me-2"
                      >
                        <path
                          d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z"
                          fill="white"
                        />
                      </svg>
                      Restrict:
                    </div>
                    <p>
                      {" "}
                      Limit the processing of your data under certain
                      conditions.
                    </p>
                  </li>
                  <li>
                    <div className="me-2 text-white">
                      <svg
                        width="8"
                        height="7"
                        viewBox="0 0 8 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="me-2"
                      >
                        <path
                          d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z"
                          fill="white"
                        />
                      </svg>
                      Object:
                    </div>
                    <p>
                      {" "}
                      Object to the processing of your data for specific
                      purposes.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="post-details">
                <h5>7. Changes to This Policy</h5>
                <p>
                  We may update this Privacy Policy from time to time. We will
                  notify you of any significant changes by posting the new
                  policy on our website.
                </p>
              </div>
              <div className="post-details">
                <h5>8. Contact Us</h5>
                <p>
                  If you have any questions or concerns about this Privacy
                  Policy, please contact us at{" "}
                  <a href="mailto:Info@pco.land">Info@pco.land</a>.
                </p>
              </div>
            </article>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
