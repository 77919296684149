import { useContext } from "react";
import { fixPrecisionAndFormat } from "@/utils/fixPrecisionAndFormat";
import { MapContext } from "../../context.jsx";

const Sidebar = ({ isLoading, buyLandHandler }) => {
  const { tileNumber, emptySelectedCells, openTileModal, titleInfo } =
    useContext(MapContext);
  return (
    <div className={`cesium-sidebar ${openTileModal ? "show" : ""}`}>
      <div className="cesium-sidebar__box">
        <div className="cesium-sidebar__header">
          <h2>FREE LAND</h2>
        </div>
        <div className="mt-3 mt-md-5">
          <div className="d-flex justify-content-between fw-bolder fs-4">
            <div className="d-flex gap-4">
              <i className="bi bi-globe-americas"></i>
              <div className="">Selected Tiles</div>
            </div>
            <div>{tileNumber}</div>
          </div>
          <div className="d-flex justify-content-between fw-bolder fs-4 mt-3">
            <div className="d-flex gap-4">
              <i className="bi bi-currency-dollar"></i>
              <div>value</div>
            </div>
            <div>
              <i className="bi bi-currency-dollar"></i>
              {fixPrecisionAndFormat(tileNumber * titleInfo.tilePrice, 2)}
            </div>
          </div>
          <div className="d-flex justify-content-between fs-6 mt-3 ms-5">
            <div>Current value per tile</div>
            <div>
              <i className="bi bi-currency-dollar"></i>
              {titleInfo.tilePrice}
            </div>
          </div>
          <div className="d-flex justify-content-between fs-6 mt-3 ms-5">
            <div>Growth since creation</div>
            <div>{titleInfo.tileGrowth}%</div>
          </div>
        </div>

        <div className="mt-3 mt-md-4">
          <h3>NOTE</h3>
          <div className="fs-6 mt-3">
            A <span className="fw-bolder">minimum</span> of 10 tiles must be
            selected.
          </div>
          <div className="mt-2 fs-6">
            A <span className="fw-bolder">maximum</span> of 100,000 tiles must
            be selected.
          </div>
        </div>
        <div className="d-flex justify-content-between fw-bolder fs-4 mt-4">
          <div className="d-flex gap-4">
            <div className=""> Delete Selected Tiles</div>
          </div>
          <button
            type="button"
            className="cesium-sidebar__btn-close"
            onClick={() => emptySelectedCells()}
            disabled={isLoading}
          >
            <i className="bi bi-trash3"></i>
          </button>
        </div>
        <button
          className="cesium-sidebar__btn-buy"
          onClick={buyLandHandler}
          disabled={isLoading}
        >
          {isLoading ? <div className="spinner-border"></div> : "Buy Now"}
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
