const Menu = [
  { is_header: true, title: "Navigation" },

  { path: "/dashboard/stakes", icon: "fa fa-boxes-stacked", title: "Stakes" },

  { path: "/dashboard/profile", icon: "bi bi-people", title: "Profile" },
  {
    path: "/dashboard/purchased-lands",
    icon: "fa fa-duotone fa-money-bills",
    title: "Purchased lands",
  },
  {
    path: "/dashboard/my-NFTs",
    icon: "bi bi-card-image",
    title: "My NFTs",
  },

  {
    path: "/dashboard/my-referrals",
    icon: "fa fa-solid fa-people-group",
    title: "My Referrals",
  },
];

export default Menu;
