import React from "react";
import LandRow from "./LandRow";
import LoadingSpinner from "./LoadingSpinner";
import NoData from "./NoData";

const LandTable = ({ userTiles, isLoading, handleConvertToNFT, ComingSoonMessage }) => {
  return (
    <div className="table-responsive mb-5">
      <table className="table table-hover text-nowrap table-bordered">
        <thead>
          <tr>
            <th scope="col">Land ID</th>
            <th scope="col">Number of Tiles</th>
            <th scope="col" colSpan={2}>Purchase Price</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <LoadingSpinner />
          ) : userTiles && userTiles.length > 0 ? (
            userTiles.map((land, index) => (
              <LandRow
                key={index}
                land={land}
                handleConvertToNFT={handleConvertToNFT}
                ComingSoonMessage={ComingSoonMessage}
              />
            ))
          ) : (
            <NoData />
          )}
        </tbody>
      </table>
    </div>
  );
};

export default LandTable;
