import { useState, useEffect } from "react";
import { api } from "@/utils/axiosInstance";

const LandTypeSelector = ({
  landDetails,
  selectedLandType,
  setSelectedLandType,
  setLandTypes,landTypes
}) => {
  useEffect(() => {
    getLandType();
  }, [landDetails.land_id]);

  const getLandType = () => {
    api.post("/land_types/", { land_id: landDetails.land_id }).then((res) => {
      setLandTypes(res.data.land_types);
      if (res.data.land_types.length > 0) {
        setSelectedLandType(res.data.land_types[0]);
      }
    });
  };

  return (
    <div className="my-3">
      <label className="form-label">NFT Type</label>
      <select
        className="form-select mt-2"
        value={selectedLandType}
        onChange={(e) => {
          setSelectedLandType(e.target.value);
        }}
        disabled={landTypes.length === 0}
      >
        {landTypes.length ? (
          landTypes.map((type, index) => (
            <option value={type} key={index}>
              {type}
            </option>
          ))
        ) : (
          <option value="">Loading...</option>
        )}
      </select>
    </div>
  );
};

export default LandTypeSelector;
