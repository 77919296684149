const ConvertButton = ({
  approve,
  onClick,
  isBtnDisabled,
  landConvertor,
  transactionReceipt,
  isLoading,
  landData,
  priceConsumer,
}) => {
  const loadingBtnText = () => {
    const loadingStates = [
      { condition: approve.isPending, text: "Approving" },
      {
        condition: landConvertor.isPending,
        text: "Converting The Land To NFT",
      },
      {
        condition: transactionReceipt.isFetching,
        text: "Fetching Approve Data",
      },
      {
        condition: isLoading,
        text: "Getting NFT Data",
      },
      {
        condition: landData.isFetching || priceConsumer.isFetching,
        text: "Fetching Land Data",
      },
    ];

    const currentLoadingState = loadingStates.find((state) => state.condition);

    if (currentLoadingState) {
      return (
        <div>
          {currentLoadingState.text}
          <div className="spinner-border spinner-border-sm ms-2"></div>
        </div>
      );
    }

    return "Confirm";
  };
  return (
    <button
      className="btn btn-lg w-94px mt-4"
      onClick={onClick}
      disabled={isBtnDisabled}
    >
      {loadingBtnText()}
    </button>
  );
};

export default ConvertButton;
