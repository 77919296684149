import React from "react";
import { Link } from "react-router-dom";
import ActionButtons from "./ActionButtons";

const LandRow = ({ land, handleConvertToNFT, ComingSoonMessage }) => {
  return (
    <tr className={land.is_nft ? "primary-row" : ""}>
      <td className="p-md-4">{land.land_id}</td>
      <td className="p-md-4">{land.tiles_count}</td>
      <td className="p-md-4 w-185px">
        {land.purchasepriceusd} <span className="fw-bold">USDT</span>
      </td>
      <td className="p-md-4 w-185px">
        {land.purchasepricetoken} <span className="fw-bold">{land.token_type}</span>
      </td>
      <td className="d-flex gap-3 justify-content-center align-items-center p-md-4">
        <Link
          to={
            land.tiles_coordination
              ? `/lands?lat=${land.tiles_coordination[0].latitude}&lng=${land.tiles_coordination[0].longitude}`
              : "/"
          }
        >
          Open in map
        </Link>
        <ActionButtons
          land={land}
          handleConvertToNFT={handleConvertToNFT}
          ComingSoonMessage={ComingSoonMessage}
        />
      </td>
    </tr>
  );
};

export default LandRow;
