import React from "react";
import { Link } from "react-router-dom";
import { slideToggle } from "@/composables/slideToggle.js";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import logo from "@/assets/images/logo/logorec.png";

const Header=()=> {
  const toggleAppSidebarDesktop = () => {
    var elm = document.querySelector(".app");
    if (elm) {
      if (
        !(
          elm.classList.contains("app-with-top-nav") &&
          elm.classList.contains("app-without-sidebar")
        )
      ) {
        elm.classList.toggle("app-sidebar-collapsed");
      }
    }
  };

  const toggleAppSidebarMobile = () => {
    var elm = document.querySelector(".app");
    if (elm) {
      if (
        !(
          elm.classList.contains("app-with-top-nav") &&
          elm.classList.contains("app-without-sidebar")
        )
      ) {
        elm.classList.toggle("app-sidebar-mobile-toggled");
      } else {
        slideToggle(document.querySelector(".app-top-nav"));
      }
    }
  };



  return (
    <div id="header" className="app-header ">
      <div className="desktop-toggler">
        <button
          type="button"
          className="menu-toggler"
          onClick={toggleAppSidebarDesktop}
        >
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </button>
      </div>

      <div className="mobile-toggler">
        <button
          type="button"
          className="menu-toggler"
          onClick={toggleAppSidebarMobile}
        >
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </button>
      </div>

      <div className="brand">
        <Link to="/" className="brand-logo">
          <img src={logo} alt="logo" />
        </Link>
      </div>
      <div className="connect-btn">
        <ConnectButton chainStatus="none" />
      </div>

      <div className="menu">
        <div className="menu-item dropdown dropdown-mobile-full">
          <a
            href="#/"
            data-bs-toggle="dropdown"
            data-bs-display="static"
            className="menu-link"
          >
            <div className="menu-icon">
              <i className="bi bi-grid-3x3-gap nav-icon"></i>
            </div>
          </a>
          <div className="dropdown-menu fade dropdown-menu-end w-300px text-center p-0 mt-1">
            <div className="row row-grid gx-0">
              <div className="col-12">
                <Link
                  to="/"
                  className="dropdown-item text-decoration-none p-3 bg-none"
                >
                  <div>
                    <i className="bi bi-terminal h2 opacity-5 d-block my-1"></i>
                  </div>
                  <div className="fw-500 fs-10px text-inverse">Home</div>
                </Link>
              </div>
              <div className="col-12">
                <Link
                  to="/lands"
                  className="dropdown-item text-decoration-none p-3 bg-none"
                >
                  <div className="position-relative">
                    <i className="bi bi-globe h2 opacity-5 d-block my-1"></i>
                  </div>
                  <div className="fw-500 fs-10px text-inverse">Buy Land</div>
                </Link>
              </div>

            </div>
          </div>
        </div>
        <div className="menu-item dropdown dropdown-mobile-full">
          <w3m-button chainStatus="none" />
        </div>
      </div>
    </div>
  );
}

export default Header;
