import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "@/assets/images/logo/logorec.png";
import Menu from "@/config/landing-menu.jsx";
import { useAuth } from "@/context/authProvider.js";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import {  useAccount, useSwitchChain, useChainId } from "wagmi";

const Header = () => {
  const [scroll, setScroll] = useState(false);
  const { isAuthenticated } = useAuth();
  // const { openChainModal } = useChainModal();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 300);
    });
    return () => {
      setScroll({});
    };
  }, []);

  const [menuActive, setMenuActive] = useState(null);

  const handleMenuActive = () => {
    setMenuActive(!menuActive);
  };

  const [activeIndex, setActiveIndex] = useState(null);
  const handleDropdown = (index) => {
    setActiveIndex(index);
  };
  // const chaing = useChains(); //from Wagmi
  const { switchChain, chains } = useSwitchChain({
    onError(error) {
      console.log("Error", error);
    },
  });
  const { chainId, isConnected } = useAccount();
  const mainChainId = useChainId();
  // console.log({ chaing,network,chainid ,account});
  const idExists = chains.some((item) => item.id === chainId);

  useEffect(() => {
    if (isConnected && !idExists) {
      // openChainModal();
      // switchChain({ chainId: chains[1] })
    }
  }, [isConnected]);
  return (
    <header
      id="header_main"
      className={`header is-fixed ${scroll ? "is-fixed" : ""}`}
    >
      <div className="container">
        <div id="site-header-inner">
          <div className="header__logo">
            <NavLink to="/">
              <img src={logo} alt="PCO" />
            </NavLink>
          </div>
          <nav
            id="main-nav"
            className={`main-nav ${menuActive ? "active" : ""}`}
          >
            <ul id="menu-primary-menu" className="menu">
              {isAuthenticated && (
                <li
                  onClick={() => handleDropdown(Menu.length)}
                  className={`menu-item ${
                    activeIndex === Menu.length ? "active" : ""
                  }`}
                >
                  <Link to="/dashboard/profile" onClick={handleMenuActive}>
                    Dashboard
                  </Link>
                </li>
              )}
              {Menu.map((data, idx) => (
                <li
                  key={idx}
                  onClick={() => handleDropdown(idx)}
                  className={`menu-item ${
                    data.namesub ? "menu-item-has-children" : ""
                  } ${activeIndex === idx ? "active" : ""}`}
                >
                  {data.isDowanloadable ? (
                    <a
                      href={data.path}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      {data.title}
                    </a>
                  ) : (
                    <Link to={data.path} onClick={handleMenuActive}>
                      {data.title}
                    </Link>
                  )}

                  {data.namesub && (
                    <ul className="sub-menu">
                      {data.namesub.map((submenu) => (
                        <li key={submenu.id} className="menu-item">
                          <NavLink to={submenu.links}>{submenu.sub}</NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>
          <div className="d-flex align-items-center">
            <div className="connect-btn">
              {/* {isAuthenticated && (
              <Link
                to="/dashboard/profile"
                className="btn btn-outline-light me-3 rounded-5 py-2 px-4 display-4 d-none d-xxl-flex"
              >
                Dashboard
              </Link>
            )} */}
              <ConnectButton chainStatus="none" />
            </div>

            <div
              className={`mobile-button ${menuActive ? "active" : ""}`}
              onClick={handleMenuActive}
            >
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
