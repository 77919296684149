import Card from "./components/Card";
import { api } from "@/utils/axiosInstance.js";
import { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { copyToClipboard } from "@/utils/copyToClipboard.js";

const MyNFTs = () => {
  const [nftList, setNftList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { address } = useAccount();

  const getNFTData = async () => {
    setIsLoading(true);
    try {
      const res = await api.post("/dashboard_nfts/", { user_address: address });
      setNftList(res.data.user_tokens); // Assuming response data is { user_tokens: { tokenId: { ... } } }
    } catch (error) {
      console.error("Error fetching NFT data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (address) {
      getNFTData();
    }
  }, [address]);

  const nftData = nftList ? Object.entries(nftList) : [];

  return (
    <div className="container-xxl p-3 p-lg-5">
      {isLoading ? (
        <div className="text-center">
          <span className="spinner-border spinner-border-md"></span>
        </div>
      ) : nftData.length > 0 ? (
        <div className="row g-3 g-xl-4 mb-5">
          <h3>How to Import an NFT into Your Wallet</h3>
          <p className="fs-5">
            To manually import an NFT into your wallet, open your wallet and go
            to the "NFT" tab.
            <br />
            Click on "Import NFTs" or "Add Token." <br /> Enter the token
            address:
            <div
              className="input-group flex-nowrap my-2"
              style={{ maxWidth: "max-content" }}
            >
              <input
                type="text"
                className="form-control"
                id="username"
                placeholder="username"
                defaultValue={
                  process.env.REACT_APP_LAND_TO_NFT_CONTRACT_ADDRESS
                }
                readOnly
              />
              <span
                className="input-group-text"
                onClick={() =>
                  copyToClipboard(
                    process.env.REACT_APP_LAND_TO_NFT_CONTRACT_ADDRESS
                  )
                }
              >
                <i className="bi me-1 bi-copy"></i>
              </span>
            </div>
            in the "Address" field and the Token/Land ID of your NFT in the "Token
            ID" field. <br /> Confirm by clicking "Add" or "Import."
          </p>
          {nftData.map(([id, token]) => (
            <div key={id} className="col-xl-5 col-xxl-4 col-lg-6 col-12">
              <Card
                tokenId={id}
                tile_count={token.tile_count}
                purchasepriceusd={token.purchasepriceusd}
                image={`data:image/png;base64,${token.picture}`}
                landtype={token.landtype}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center p-3">
          <div className="text-gray-300 mb-2">
            <i className="fas fa-fw me-2 fa-database fa-3x"></i>
          </div>
          You have no NFT.
        </div>
      )}
    </div>
  );
};

export default MyNFTs;
