import React, { useRef, useState } from "react";
import VideoJS from "./Video";
import videojs from "video.js";
import img from "@/assets/images/background/bg-video.jpeg";

const HeroVideo = () => {
  const url = "https://assets.pco.land/IMG_2893_1.MP4";
  const playerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true); // Track if the video is playing

  const videoJsOptions = {
    autoplay: false,
    preload: "auto",
    controls: false,
    responsive: true,
    fluid: true,
    loop: true,
    poster: img, // Set the poster image URL
    sources: [
      {
        src: url,
        type: "video/mp4",
      },
    ],
    html5: {
      vhs: {
        overrideNative: !videojs.browser.IS_SAFARI, // Ensures iOS plays the video correctly
      },
    },
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // Ensure `playsinline` is set for mobile browsers
    const videoElement = player.el().querySelector("video");
    videoElement.setAttribute("playsinline", "playsinline");
    videoElement.setAttribute("webkit-playsinline", "webkit-playsinline");
    player.muted(true); // Ensure it's muted
    player
      .play()
      .then(() => {
        setIsPlaying(true);
      })
      .catch((error) => {
        setIsPlaying(false);
        console.log("Autoplay failed:", error);
      });
  };

  const handlePlay = () => {
    if (playerRef.current) {
      playerRef.current.muted(true); // Ensure it's muted
      playerRef.current
        .play()
        .then(() => {
          setIsPlaying(true);
        })
        .catch((error) => {
          console.log("Manual play failed:", error);
          setIsPlaying(false);
        });
    }
  };

  return (
    <section className="hero-video">
      <div className="video-responsive">
        <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
      </div>
      {!isPlaying && (
        <div className="video-overlay" onClick={handlePlay}>
          <button className="play-button">
            <i className="fas fa-fw fa-play"></i>
          </button>
        </div>
      )}

      <div className={`hero-video__info`}>
        <div className="box-slider__main">
          <div className="container">
            <div className="row">
              <div className="content-box">
                <h1 className="title">
                  Welcome to <br />
                  PCO Meta Earth
                </h1>
                <p className="sub-title">
                  Revolutionizing Digital Ownership with Polygon Blockchain
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroVideo;
