import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

BlogItem.propTypes = {
  item: PropTypes.object,
};

function BlogItem(props) {
  const { item } = props;

  return (
    <div className="post-item">
      <div className="wrap">
        <Link to={`/blog/${item.id}`} className="tag">
          {item.category}
        </Link>
        <div className="image">
          <img src={item.img} alt="Crybox" />
        </div>
        <div className="content-item">
          <div className="content">
            <div className="meta-post">
              <Link to={`/blog/${item.id}`}>{item.time}</Link>
            </div>
            <div className="h7 title">
              <Link to={`/blog/${item.id}`}>{item.title}</Link>
            </div>
            {/* <p>{item.text}</p> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogItem;
