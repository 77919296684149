import React, { useEffect, useState, useRef } from "react";
import AOS from "aos";
import Footer from "./Footer";
import Header from "./Header";
import { useLocation } from "react-router-dom";
import { gsap } from "gsap";
import { CustomEase } from "gsap/CustomEase";
import videoMP4 from "@/assets/videos/preloader-video.mp4";
import videoWebM from "@/assets/videos/preloader-video.webm";

gsap.registerPlugin(CustomEase);

const HomeLayout = ({ element }) => {
  const [isPreloaderVisible, setIsPreloaderVisible] = useState(true);
  const [autoplayBlocked, setAutoplayBlocked] = useState(false);
  const [videoPlayed, setVideoPlayed] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const preloaderRef = useRef(null);
  const videoRef = useRef(null);

  // const startAnimation = () => {
  //   CustomEase.create("default", "0.24, 1, 0.36, 1");

  //   gsap
  //     .timeline()
  //     .to(videoRef.current, {
  //       scale: 8,
  //       opacity: 0,
  //       duration: 0.25,
  //       ease: "none",
  //     })
  //     .to(preloaderRef.current, {
  //       opacity: 0,
  //       duration: 0.8,
  //       onComplete: () => {
  //         setIsPreloaderVisible(false);
  //       },
  //     })
  //     .to(
  //       ".hero-animate",
  //       { scale: 1, duration: 0.7, ease: "default", clearProps: "scale" },
  //       "<"
  //     )
  //     .to(
  //       document.body,
  //       { backgroundColor: "#000", duration: 0.5, ease: "default" },
  //       "<"
  //     )
  //     .to(
  //       document.body,
  //       { backgroundColor: "#0c1226", duration: 1, ease: "default" },
  //       "<"
  //     );
  // };

  // const handleVideoError = (e) => {
  //   console.error("Error playing video:", e);
  //   // Skip the preloader or provide fallback content
  //   setIsPreloaderVisible(false);
  // };

  // const handleCanPlay = () => {
  //   console.log("Video can play");
  //   const playPromise = videoRef.current.play();

  //   if (playPromise) {
  //     playPromise.catch((error) => {
  //       console.warn("Autoplay blocked, prompting user interaction.", error);
  //       setAutoplayBlocked(true); // Show a prompt for user interaction
  //     });
  //   }
  // };

  // const handleVideoEnded = () => {
  //   startAnimation(); // Trigger the animation on video end
  // };

  // const handleUserInteraction = () => {
  //   if (autoplayBlocked && videoRef.current) {
  //     videoRef.current
  //       .play()
  //       .then(() => {
  //         setAutoplayBlocked(false); // Reset the autoplayBlocked state
  //         setVideoPlayed(true);
  //       })
  //       .catch((error) => {
  //         console.error("User interaction failed to start the video:", error);
  //         setIsPreloaderVisible(false); // Skip the preloader if playback fails
  //       });
  //   }
  // };

  // useEffect(() => {
  //   if (isHomePage) {
  //     // Video playback setup
  //     const videoElement = videoRef.current;
  //     if (videoElement) {
  //       videoElement.addEventListener("error", handleVideoError);
  //       videoElement.addEventListener("canplay", handleCanPlay);
  //       videoElement.addEventListener("ended", handleVideoEnded);
  //     }

  //     return () => {
  //       if (videoElement) {
  //         videoElement.removeEventListener("error", handleVideoError);
  //         videoElement.removeEventListener("canplay", handleCanPlay);
  //         videoElement.removeEventListener("ended", handleVideoEnded);
  //       }
  //     };
  //   } else {
  //     setIsPreloaderVisible(false);
  //   }
  // }, [isHomePage]);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  useEffect(() => {
    document.body.classList.add("landing-body");

    return () => {
      document.body.classList.remove("landing-body");
    };
  }, [isPreloaderVisible, isHomePage]);
  return (
    <div >
      {/* {isHomePage && isPreloaderVisible && (
        <div className="preloader" ref={preloaderRef}>
          <div className="preloader__inner">
            <video
              muted
              playsInline
              ref={videoRef}
              className="preloader__video"
              crossOrigin=""
            >
              <source src={videoMP4} type="video/mp4" />
              <source src={videoWebM} type="video/webm" />
              Your browser does not support the video tag.
            </video>
            {autoplayBlocked && (
              <div className="autoplay-blocked-message">
                Autoplay is blocked. Click anywhere to start the video.
              </div>
            )}
          </div>
        </div>
      )} */}

      <Header />
      {element}
      <Footer />
    </div>
  );
};

export default HomeLayout;
