import { fixPrecisionAndFormat } from "@/utils/fixPrecisionAndFormat";

const BaseModal = ({
  closeModalHandler,
  tilePrice,
  contractData,
  buyLandHandler,
  isDisabled,
  purchaseLand,
  loadingBtnText,
  cancelBuyLand,
}) => {
  return (
    <div className="dark-modal buy-land-modal">
      <div className="modal show" id="modalSm" aria-modal="true" role="dialog">
        <div className="modal-dialog modal-md h-100 d-flex align-items-center">
          <div className="modal-content">
            {purchaseLand.isSuccess ? (
              <div className="modal-body">
                <div className="p-2 p-md-4 text-center fw-bold fs-5 fs-md-4">
                  Now Redirecting to Purchased Lands
                </div>
                <div className="d-flex m-auto spinner-grow text-center"></div>
              </div>
            ) : (
              <>
                <div className="modal-header">
                  <h5 className="modal-title">Payment Options</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      cancelBuyLand();
                      closeModalHandler();
                    }}
                  ></button>
                </div>
                <div className="modal-body">
                  {/* <Approve/> */}
                  <div className="mt-2">
                    <div className="d-flex justify-content-between fw-bolder  fs-5 fs-md-4">
                      <div className="d-flex gap-2 gam-md-4">
                        <i className="bi bi-globe-americas"></i>
                        <div className="">Selected Tiles</div>
                      </div>
                      <div>{contractData.tile_count}</div>
                    </div>
                    <div className="d-flex justify-content-between fw-bolder  fs-5 fs-md-4 mt-3">
                      <div className="d-flex gap-2 gam-md-4">
                        <i className="bi bi-currency-dollar"></i>
                        <div>value</div>
                      </div>
                      <div>
                        <i className="bi bi-currency-dollar"></i>
                        {fixPrecisionAndFormat(
                          contractData.tile_count * tilePrice,
                          2
                        )}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between fw-bolder fs-5 fs-md-4 mt-3">
                      <div className="d-flex gap-2 gam-md-4">
                        <i className="bi bi-currency-dollar"></i>
                        <div>Current value per tile</div>
                      </div>
                      <div>
                        <i className="bi bi-currency-dollar"></i>
                        {tilePrice}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between fw-bolder fs-5 fs-md-4 mt-3">
                      <div className="d-flex gap-2 gam-md-4">
                        <div className="ms-4">{contractData.token_type}</div>
                      </div>
                      <div>{contractData.purchase_price_token}</div>
                    </div>
                    <div className="fw-bolder fs-5 fs-md-4 mt-3 mb-2">
                      Select Token
                    </div>
                    <p className="text-muted">
                      Currently, only <span className="fw-bolder">PMB</span>{" "}
                      tokens are available for payment.
                    </p>
                  </div>
                  <div>
                    <button
                      className="btn btn-lg w-94px"
                      onClick={buyLandHandler}
                      disabled={isDisabled}
                    >
                      {loadingBtnText}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
};

export default BaseModal;
