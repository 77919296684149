import { Navigate } from "react-router-dom";
import { useAuth } from "../context/authProvider";
import Loading from "@/components/Common/Loading";

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, isFetchingUserData, isLoading } = useAuth();

  if (isFetchingUserData || isLoading) {
    return <Loading />;
  }
  return isAuthenticated ? children : <Navigate to="/" />;
};
export default PrivateRoute;
