import React, { createContext, useState, useRef, useEffect } from "react";
import { api } from "@/utils/axiosInstance.js";
import { removeSelectedPolygons } from "./helpers";
const MapContext = createContext();

const MapProvider = ({ children }) => {
  const [selectedCellsHistory, setSelectedCellsHistory] = useState([]);
  const [selectedCells, setSelectedCells] = useState([]);
  const [clickCount, setClickCount] = useState(0);
  const [coordinates, setCoordinates] = useState(null);
  const [isPurchased, setIsPurchased] = useState(false);
  const [userOwnedTiles, setUserOwnedTiles] = useState([]);
  const [openTileModal, setOpenTileModal] = useState(false);
  const [titleInfo, setTileInfo] = useState({
    tilePrice: "0",
    tileGrowth: "0",
  });
  const viewerRef = useRef(null);
  const handlerRef = useRef(null);

  const undoHandler = () => {
    if (viewerRef.current) {
      const viewer = viewerRef.current;

      setSelectedCellsHistory((prevHistory) => {
        const newHistory = [...prevHistory];
        const lastState = newHistory.pop();
        if (lastState) {
          const newSelectedCells = selectedCells.filter(
            (item) =>
              !lastState.some((tile) => tile.identifier === item.identifier)
          );

          removeSelectedPolygons(viewer, lastState);
          setSelectedCells(newSelectedCells);
          setClickCount((prevCount) => Math.max(prevCount - 1, 0));
        }
        if (newHistory.length === 0) {
          setSelectedCells([]);
        }
        return newHistory;
      });
    }
  };
  const toggleModal = () => {
    setOpenTileModal(!openTileModal);
  };
  const emptySelectedCells = () => {
    setSelectedCells([]);
    setOpenTileModal(false);
    setClickCount(0);
    if (viewerRef.current) {
      const viewer = viewerRef.current;
      removeSelectedPolygons(viewer, selectedCells);
    }
  };
  const tileNumber = selectedCells.length;

  const getTileInfo = () => {
    api
      .get("/tile_price_growth/")
      .then((res) =>
        setTileInfo({
          tilePrice: res.data.tile_price,
          tileGrowth: res.data.growth_percentage,
        })
      )
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getTileInfo();
  }, []);
  return (
    <MapContext.Provider
      value={{
        viewerRef,
        handlerRef,
        selectedCells,
        setSelectedCells,
        clickCount,
        setClickCount,
        undoHandler,
        coordinates,
        setCoordinates,
        isPurchased,
        setIsPurchased,
        userOwnedTiles,
        setUserOwnedTiles,
        openTileModal,
        emptySelectedCells,
        toggleModal,
        tileNumber,
        titleInfo,
        setSelectedCellsHistory
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export { MapContext, MapProvider };
