import React, { useEffect } from "react";
import { useSignMessage, useAccount } from "wagmi";
import { useAuth } from "@/context/authProvider.js";

const SignModal = () => {
  const { showSignModal, setShowSignModal, handleDisconnection, verifyUser } =
    useAuth();
  const closeModalHandler = () => {
    setShowSignModal(false);
    handleDisconnection();
  };
  const { address, isDisconnected } = useAccount();
  const { signMessage, data: signature, isSuccess, isError } = useSignMessage();
  useEffect(() => {
    if (isError) {
      closeModalHandler();
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      setShowSignModal(false);
      verifyUser(signature);
    }
  }, [isSuccess, signature]);

  useEffect(() => {
    if (isDisconnected) {
      closeModalHandler();
    }
  }, [isDisconnected]);
  return (
    <div
      className="dark-modal"
      style={{ display: showSignModal ? "block" : "none" }}
    >
      <div className="modal show" id="modalSm" aria-modal="true" role="dialog">
        <div className="modal-dialog modal-sm h-100 d-flex align-items-center">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Sign Message</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={() => closeModalHandler()}
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <p>
                  To proceed, please sign the message using your wallet. This
                  will verify your identity and allow us to perform secure
                  actions on your behalf.
                </p>
                <button
                  className="btn btn-lg"
                  onClick={() => signMessage({ message: address })}
                >
                  {" "}
                  Sign
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
};

export default SignModal;
