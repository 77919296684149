import React from "react";
import PropTypes from "prop-types";

WorkItem.propTypes = {
  item: PropTypes.object,
};

function WorkItem(props) {
  const { item } = props;

  return (
    <div className={`box-text st2 corner-box ${item.active}`}>
      <div className="number">{item.numb}</div>
      <h5 className="">{item.title}</h5>
      <p>{item.text}</p>
    </div>
  );
}

export default WorkItem;
