import { useContext } from "react";
import { fixPrecisionAndFormat } from "@/utils/fixPrecisionAndFormat";
import { MapContext } from "../context.jsx";

const BuyLandMobileNavbar = ({ toggleModal }) => {
  const { tileNumber, titleInfo } = useContext(MapContext);
  return (
    <div className="cesium-mobile-nav">
      <div className="d-flex justify-content-between fw-bolder align-items-center h-100">
        <div className="d-flex">
          <div className="d-flex gap-2">
            <div className="">Tiles:</div>
          </div>
          <div className="text-center ms-2">{tileNumber}</div>
        </div>
        <div className="d-flex">
          <div className="d-flex gap-2">
            <div className="">Value:</div>
          </div>
          <div className="text-center ms-2">
            $ {fixPrecisionAndFormat(tileNumber * titleInfo.tilePrice, 2)}
          </div>
        </div>
        <div className="circle" onClick={toggleModal}>
          <i className="bi bi-bag-plus-fill"></i>
        </div>
      </div>
    </div>
  );
};

export default BuyLandMobileNavbar;
