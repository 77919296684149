import React from "react";
import PropTypes from "prop-types";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

News.propTypes = {
  data: PropTypes.array,
};

function News(props) {
  const { data } = props;

  return (
    <section className="tf-section partner">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="tf-title"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <p className="h8 sub-title">NEWS</p>
              <h4 className="title mb-5">WE ARE IN NEWS</h4>
            </div>
          </div>
          <div className="col-md-12 mt-4">
            <div
              className="item-parner"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              {data.map((item, idx) => (
                <a
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="image"
                  key={idx}
                >
                  <img src={item.img} alt="PCO" />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default News;
