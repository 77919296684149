import React, { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { api } from "@/utils/axiosInstance.js";
import LoadingSpinner from "./components/LoadingSpinner";
import NoData from "./components/NoData";

const Referees = () => {
  const [referees, setReferees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { address } = useAccount();

  const getRefereesData = () => {
    setIsLoading(true);
    api
      .post("/all_user_subsets/", {
        user_address: address,
      })
      .then((res) => {
        setReferees(res.data.user_subsets);
      })
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    getRefereesData();
  }, []);
  return (
    <div
      id="tableHeadOptions"
      className="table-responsive mt-5 mb-5 text-center"
    >
      <table className="table table-hover text-nowrap table-bordered">
        <thead>
          <tr>
            <th scope="col" colSpan={2}>
              Username
            </th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <LoadingSpinner />
          ) : referees && referees.length > 0 ? (
            referees.map((referee, index) => (
              <tr key={index}>
                <td className="p-md-3 align-middle" colSpan={1}>
                  {index + 1}
                </td>
                <td
                  className="p-md-3 text-left"
                  style={{ width: "94%", textAlign: "left" }}
                >
                  <span className="me-5"> {referee} </span>

                  <span className="ml-4 badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                    <i className="fa fa-circle fs-9px fa-fw me-5px"></i> Active
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <NoData />
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Referees;
