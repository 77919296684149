import React, { useEffect, useContext } from "react";
import { useIsMobile } from "@/hooks/useIsMobile.js";
import { useLandStore } from "@/store";
import { useLocation } from "react-router-dom";
import { useAllTilesData } from "@/hooks/useAllTilesData.js";
import useMapViewer from "../hooks/useMapViewer";
import { MapContext } from "../context";
import BuyLandMobileNavbar from "../BuyLandMobileNavbar/index.jsx";
import BuyLandSidebar from "../BuyLandSidebar/index.jsx";

const BuyLandMap = () => {
  const {
    viewerRef,
    handlerRef,
    selectedCells,
    setSelectedCells,
    clickCount,
    setClickCount,
    undoHandler,
    coordinates,
    setCoordinates,
    isPurchased,
    setIsPurchased,
    userOwnedTiles,
    setUserOwnedTiles,
    openTileModal,
    toggleModal,
    setSelectedCellsHistory,
  } = useContext(MapContext);

  const location = useLocation();
  const isMobile = useIsMobile(1025);
  const { userTiles, allTiles } = useLandStore();
  const { getAllTileData, isLoading } = useAllTilesData();

  useMapViewer({
    viewerRef,
    handlerRef,
    setSelectedCells,
    selectedCells,
    clickCount,
    setClickCount,
    coordinates,
    allTiles,
    userTiles,
    isPurchased,
    setIsPurchased,
    userOwnedTiles,
    setUserOwnedTiles,
    setCoordinates,
    isLoading,
    setSelectedCellsHistory,
  });

  useEffect(() => {
    if (coordinates) getAllTileData(coordinates);
  }, [location.search, coordinates]);

  return (
    <div id="cesiumContainer" className="cesium-container">
      {isMobile ? (
        <>
          <BuyLandMobileNavbar toggleModal={toggleModal} />
          {openTileModal && <BuyLandSidebar />}
        </>
      ) : (
        ""
      )}
      <BuyLandSidebar />
      <button
        className="cesium-button cesium-toolbar-button cesium-home-button cesium-undo-btn"
        onClick={undoHandler}
      >
        <i className="fs-3 bi bi-arrow-counterclockwise"></i>
      </button>
      <div className="cesium-hint__box">
        <div className="d-flex align-items-center">
          <span className="cesium-hint__shape orange" />
          <div>Sold Tiles</div>
        </div>
        <div className="d-flex align-items-center">
          <span className="cesium-hint__shape purple" />
          <div className="">Your Lands</div>
        </div>
      </div>
    </div>
  );
};

export default BuyLandMap;
