import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AuthProvider, Web3ModalProvider } from "./context";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import SignModal from "./components/Common/SignModal";
import GoogleAnalytics from "./components/GoogleAnalytics";
import "react-toastify/dist/ReactToastify.css";
import LandProvider from "./context/landProvider";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Web3ModalProvider>
      <AuthProvider>
        <LandProvider>
          <Outlet />
          <GoogleAnalytics />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <SignModal />
        </LandProvider>
      </AuthProvider>
    </Web3ModalProvider>
  );
}

export default App;
