import React, { useState } from "react";
import PropTypes from "prop-types";
RoadMapItem.propTypes = {
  item: PropTypes.object,
};

function RoadMapItem(props) {
  const { item } = props;
  return (
    <div
      key={item.id}
      className={`rm-box ${item.positon}`}
      data-aos="zoom-in"
      data-aos-duration="1200"
    >
      <div className={`corner-box ${item.style}`}>
        <h5>{item.time}</h5>
        <h5>{item.title}</h5>
        <ul>
          {item.list.map((li, idx) => (
            <li key={idx}>{li.text}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}
RoadMap.propTypes = {
  data: PropTypes.array,
};

function RoadMap(props) {
  const { data } = props;

  const [dataBlock] = useState({
    subtitle: "ROADMAP",
    title: "PCO Timeline",
  });

  return (
    <section className="tf-section tf-roadmap" id="roadmap">
      <div className="overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="tf-title mb-30"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <p className="h8 sub-title">{dataBlock.subtitle}</p>
              <h4 className="title">{dataBlock.title}</h4>
            </div>
          </div>
          <div className="col-md-12">
            <div className="roadmap">
              {data.map((item) => (
                <RoadMapItem key={item.id} item={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RoadMap;
