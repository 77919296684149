import React, { useEffect, useState } from "react";
import { useAuth } from "@/context/authProvider.js";
import { useAccount, useBalance } from "wagmi";
import { formatUnits } from "viem";
import {
  pmbTokenAddress,
  pmeTokenAddress,
} from "@/utils/contractConstants/tokenAddress";
import Form from "./components/Form";

const Profile = () => {
  const [PMBValue, setPMBValue] = useState("-");
  const [PMEValue, setPMEValue] = useState("-");

  const { user } = useAuth();
  const { address } = useAccount();

  const PMBResult = useBalance({
    address,
    token: pmbTokenAddress,
  });
  const PMEResult = useBalance({
    address,
    token: pmeTokenAddress,
  });

  useEffect(() => {
    if (PMBResult.data) {
      const PMBVal = formatUnits(
        PMBResult.data?.value,
        PMBResult.data?.decimals
      );
      setPMBValue(PMBVal);
    }
  }, [PMBResult]);

  useEffect(() => {
    if (PMEResult.data) {
      const PMEVal = formatUnits(
        PMEResult.data?.value,
        PMEResult.data?.decimals
      );
      setPMEValue(PMEVal);
    }
  }, [PMEResult]);

  return <Form user={user} PMBValue={PMBValue} PMEValue={PMEValue} />;
};

export default Profile;
