import React from "react";
import { Link, useParams } from "react-router-dom";
import blogData from "../Home/data/blogData";
import Blog from "../Home/components/blog";

const BlogDetails = () => {
  const { id } = useParams<{ id?: string }>();
  const blogIndex = id ? parseInt(id, 10) - 1 : -1;

  const blog =
    blogIndex >= 0 && blogIndex < blogData.length ? blogData[blogIndex] : null;

  if (!blog) {
    return <div>Blog not found</div>;
  }

  return (
    <div className="post-details">
      <section className="page-title">
        <div className="image">
          <img src={blog.headerImg} alt="Blog Header" />
        </div>
      </section>
      <section className="tf-section detail">
        <div className="container">
          <div className="row">
            <article
              className="article"
              data-aos="fade-in"
              data-aos-duration="800"
            >
              <div className="post p-b23">
                <div className="image">
                  <div className="tag st2">{blog.category}</div>
                </div>
                <h4 className="title">{blog.title}</h4>
                <ul className="meta-post">
                  <li>
                    <span>by</span>
                    <Link to="#">Admin</Link>
                  </li>
                  <li>
                    <Link to="#">20 Jun 2022</Link>
                  </li>
                </ul>
              </div>
              <div className="post-details">
                {blog.paragraphs.map((item, index) => (
                  <div key={index} className="mb-5">
                    {item.img && (
                      <img src={item.img} alt="Blog Content" className="mb-5" />
                    )}
                    <h5>{item.title}</h5>
                    <p>{item.text}</p>
                  </div>
                ))}
              </div>
              <div className="wrap-widget">
                <div className="widget widget-tag style-2">
                  <h6 className="title-widget">Tags:</h6>
                  <ul>
                    <li>
                      <Link to="#">Avatar</Link>
                    </li>
                  </ul>
                </div>
                <div className="widget widget-social style-2">
                  <h6 className="title-widget">Share:</h6>
                  <ul>
                    <li>
                      <Link to="#">
                        <i className="fab fa-facebook-f"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fab fa-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fab fa-instagram"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fab fa-youtube"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="wrap-box-details">
                <div className="previous">
                  <Link className="st" to={`/blog/${blogIndex}`}>
                    Previous
                  </Link>
                  <h6>
                    <Link to={`/blog/${blogIndex}`}>
                      {blogData[blogIndex - 1]?.title || "No Previous Blog"}
                    </Link>
                  </h6>
                </div>
                <div className="next">
                  <Link className="st" to={`/blog/${blogIndex + 2}`}>
                    Next
                  </Link>
                  <h6>
                    <Link to={`/blog/${blogIndex + 2}`}>
                      {blogData[blogIndex + 1]?.title || "No Next Blog"}
                    </Link>
                  </h6>
                </div>
              </div>
            </article>
          </div>
        </div>
      </section>

      <Blog data={blogData} />
    </div>
  );
};

export default BlogDetails;
