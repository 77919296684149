import React from "react";
import App from "./../app.jsx";


import Profile from "./../pages/Dashboard/Profile";
import Staking from "../pages/Dashboard/Staking";
import PurchasedLands from "../pages/Dashboard/PurchasedLands";
import Referees from "../pages/Dashboard/Referees";
import MyNFTs from "../pages/Dashboard/MyNFTs";



import PagesError from "./../pages/Public/Error";
import Home from "../pages/Public/Home";
import Lands from "../pages/Public/Lands";
import BlogDetails from "../pages/Public/BlogDetails";
import TermsAndConditions from "../pages/Public/TermsAndConditions";
import PrivacyPolicy from "../pages/Public/PrivacyPolicy";



import Signup from "../pages/Auth/Signup";
import PublicRoute from "../utils/publicRoute.js";
import DashbaordLayout from "@/components/Layout/DashbaordLayout";
import LandingLayout from "@/components/Layout/HomeLayout";

const AppRoute = [
  {
    path: "*",
    element: <App />,
    children: [
      { path: "", element: <LandingLayout element={<Home />} /> },
      {
        path: "lands",
        element: <LandingLayout element={<Lands />} />,
      },
      {
        path: "blog/:id",
        element: <LandingLayout element={<BlogDetails />} />,
      },
      {
        path: "privacy-policy",
        element: <LandingLayout element={<PrivacyPolicy />} />,
      },
      { path: "terms-conditions", element: <LandingLayout element={<TermsAndConditions />} /> },
      {
        path: "dashboard",
        element: <DashbaordLayout />,
        children: [

          { path: "stakes", element: <Staking /> },
          { path: "profile", element: <Profile /> },
          {
            path: "purchased-lands",
            element: <PurchasedLands />,
          },
          {
            path: "my-NFTs",
            element: <MyNFTs />,
          },
          {
            path: "my-referrals",
            element: <Referees />,
          },
        ],
      },
      { path: "signup", element: <PublicRoute element={<Signup />} /> },
      { path: "*", element: <PagesError /> },
    ],
  },
];

export default AppRoute;
