import { ConnectButton } from "@rainbow-me/rainbowkit";

const ConnectWalletModal = ({ closeModalHandler }) => {
  return (
    <div className="dark-modal">
      <div className="modal show" id="modalSm" aria-modal="true" role="dialog">
        <div className="modal-dialog modal-sm h-100 d-flex align-items-center">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Connect Your Wallet</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={() => closeModalHandler()}
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <p>
                  To continue and purchase land, please connect your wallet.
                </p>
                <div className="connect-btn d-flex justify-content-center">
                  <ConnectButton chainStatus="none" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
};

export default ConnectWalletModal;
