import React from "react";

const ActionButtons = ({ land, handleConvertToNFT, ComingSoonMessage }) => {
  return (
    <>
      {land.is_nft ? (
        ""
      ) : (
        <>
          <button
            onClick={() =>
              handleConvertToNFT(
                land.tiles_coordination,
                land.land_id,
                land.purchasepricetoken,
                land.token_type
              )
            }
            className="bg-gray-500 text-white rounded p-2 h-md-40px"
          >
            Convert to NFT
          </button>
          <button
            onClick={ComingSoonMessage}
            className="bg-danger text-white rounded p-2 px-3 h-md-40px"
          >
            Sell
          </button>
        </>
      )}
    </>
  );
};

export default ActionButtons;
