import { useRef, useEffect, useState } from "react";
import { api } from "@/utils/axiosInstance";
import {
  useWriteContract,
  useWaitForTransactionReceipt,
  useAccount,
  useReadContract,
} from "wagmi";
import {
  pmbTokenAddress,
  approveAbi,
  landNFTAbi,
  landAbi,
  priceConsumerV3Abi,
} from "@/utils/contractConstants";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import LandTypeSelector from "./LandTypeSelector";
import Map from "./Map";
import ConvertButton from "./ConvertButton";
import ModalContainer from "./ModalContainer";

const Modal = ({ landDetails, closeModalHandler }) => {
  const navigate = useNavigate();

  const viewerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [sid, setSid] = useState();
  const [landTypes, setLandTypes] = useState([]);
  const [selectedLandType, setSelectedLandType] = useState(""); // Added state for selected land type
  const { address } = useAccount();
  const queryClient = useQueryClient();

  const approve = useWriteContract();
  const landConvertor = useWriteContract();
  const landData = useReadContract({
    address: process.env.REACT_APP_LAND_CONTRACT_ADDRESS,
    abi: landAbi,
    functionName: "lands",
    args: [landDetails.land_id],
  });

  const priceConsumer = useReadContract({
    address: "0x4dF3e21Ba6D6277a6622b50b614180362985E109",
    abi: priceConsumerV3Abi,
    functionName: "getUSDToTokenAmount",
    args: [landData?.data?.[4], 2],
  });

  const approveHandler = async () => {
    await approve.writeContractAsync({
      address: pmbTokenAddress,
      abi: approveAbi,
      functionName: "approve",
      args: [
        process.env.REACT_APP_LAND_TO_NFT_CONTRACT_ADDRESS,
        priceConsumer?.data,
      ],
    });
  };

  const convertLandToNFT = async () => {
    await landConvertor.writeContractAsync({
      address: process.env.REACT_APP_LAND_TO_NFT_CONTRACT_ADDRESS,
      abi: landNFTAbi,
      functionName: "convertLandToNFT",
      args: [landDetails.land_id, sid, landDetails.token_type],
    });
  };

  const allowance = useReadContract({
    address: pmbTokenAddress,
    abi: approveAbi,
    functionName: "allowance",
    args: [address, process.env.REACT_APP_LAND_TO_NFT_CONTRACT_ADDRESS],
    watch: true,
  });

  useEffect(() => {
    if (approve.isError) {
      if (approve.error?.cause?.code === 4001) {
        toast.error("You rejected the spending cap request for your PMB.");
        closeModalHandler();
      }
    }
  }, [approve.isError]);

  useEffect(() => {
    if (landConvertor.isSuccess) {
      toast.success("🌍 Your Land Has Been Successfully Converted to an NFT!");
      closeModalHandler();
      navigate("/dashboard/my-NFTs");
    }
  }, [landConvertor.isSuccess]);
  useEffect(() => {
    if (landConvertor.error) {
      toast.error(
        landConvertor.error?.cause?.shortMessage || "Purchase failed."
      );
      closeModalHandler();
      setIsLoading(false);
    }
  }, [landConvertor.error]);

  const transactionReceipt = useWaitForTransactionReceipt({
    hash: approve.data,
    confirmations: 3,
  });

  useEffect(() => {
    allowance.refetch();
  }, []);

  useEffect(() => {
    if (transactionReceipt.isSuccess) {
      convertLandToNFT();
      queryClient.invalidateQueries({ queryKey: allowance.queryKey });
    }
  }, [transactionReceipt.isSuccess]);

  useEffect(() => {
    if (transactionReceipt.error) {
      transactionReceipt.refetch();
    }
  }, [transactionReceipt.error]);

  const captureImage = () => {
    return new Promise((resolve, reject) => {
      if (viewerRef.current) {
        const viewer = viewerRef.current;
        viewer.scene.requestRender();
        const listener = () => {
          viewer.scene.postRender.removeEventListener(listener);
          const canvas = viewer.scene.canvas;
          const image = canvas.toDataURL("image/png");
          if (image) {
            resolve(image);
          } else {
            reject(new Error("Failed to capture image."));
          }
        };
        viewer.scene.postRender.addEventListener(listener);
      } else {
        reject(new Error("Viewer is not available."));
      }
    });
  };

  const convertButtonHandler = async () => {
    setIsLoading(true);
    try {
      const picture = await captureImage();
      await api
        .post("/get_nft/", {
          land_id: landDetails.land_id,
          picture,
          land_type: selectedLandType,
          user_address: address,
        })
        .then((res) => {
          setSid(res.data.sid);
          approveHandler();
        });
    } catch (error) {
      console.error("Error capturing image:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const isBtnDisabled =
    isLoading ||
    !isMapLoaded ||
    landConvertor.isPending ||
    transactionReceipt.isFetching ||
    approve.isPending ||
    landData.isFetching ||
    priceConsumer.isFetching ||
    landTypes.length === 0;

  return (
    <ModalContainer closeModalHandler={closeModalHandler}>
      <div>
        <Map
          landDetails={landDetails}
          setIsMapLoaded={setIsMapLoaded}
          viewerRef={viewerRef}
        />
        <LandTypeSelector
          landDetails={landDetails}
          selectedLandType={selectedLandType}
          setSelectedLandType={setSelectedLandType}
          setLandTypes={setLandTypes}
          landTypes={landTypes}
        />
        <ConvertButton
          onClick={convertButtonHandler}
          isBtnDisabled={isBtnDisabled}
          approve={approve}
          transactionReceipt={transactionReceipt}
          landConvertor={landConvertor}
          isLoading={isLoading}
          landData={landData}
          priceConsumer={priceConsumer}
        />
      </div>
    </ModalContainer>
  );
};

export default Modal;
