import React, { useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";

import { Scrollbar, A11y, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation } from "swiper/modules";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import BlogItem from "./blog-item";

Blog.propTypes = {
  data: PropTypes.array,
};

function Blog(props) {
  const { data } = props;
  const [dataBlock] = useState({
    subtitle: "BLOGS",
    title: "Lastest News",
  });

  const [swiperRef, setSwiperRef] = useState();

  const theSlides = useMemo(() => ["slide one", "slide two"], []);

  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev();
  }, [swiperRef]);

  const handleNext = useCallback(() => {
    swiperRef?.slideNext();
  }, [swiperRef]);

  return (
    <section className="tf-section blog">
      <div className="container position-relative">
        <div className="row">
          <div className="col-md-12">
            <div className="tf-title mb-60">
              <p
                className="h8 sub-title"
                data-aos="zoom-in"
                data-aos-duration="800"
              >
                {dataBlock.subtitle}
              </p>
              <h4 className="title">{dataBlock.title}</h4>
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <button className="blog-button-prev">
                <i className="fas fa-fw me-2 fa-angle-left"></i>
              </button>
            </div>
            <Swiper
              navigation={{
                nextEl: ".blog-button-next",
                prevEl: ".blog-button-prev",
              }}
              onSwiper={setSwiperRef}
              modules={[Scrollbar, A11y, Navigation]}
              spaceBetween={30}
              scrollbar={{ draggable: true }}
              breakpoints={{
                0: {
                  slidesPerView: 1.2,
                },
                767: {
                  slidesPerView: 2,
                },
                991: {
                  slidesPerView: 3,
                },
              }}
            >
              {data.map((item) => (
                <SwiperSlide key={item.id}>
                  <BlogItem item={item} />
                </SwiperSlide>
              ))}
            </Swiper>
            <div>
              <button className="blog-button-next">
                <i className="fas fa-fw me-2 fa-angle-right"></i>
              </button>
            </div>
          </div>
          {/* <div class="carousel__controls">
            <button class="my-button-prev">-</button>
            <div class="swiper-pagination" />
            <button class="my-button-next">+</button>
        </div> */}
          {/* <div className="col-md-12">
            <div className="text-center mt-5">
              <Button title="Explore more" onClick={() => setShowAll(true)} />
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default Blog;
